import { Controller } from "stimulus";

export default class extends Controller {
static targets = ["input", "error"];

    validate() {
        const value = this.inputTarget.value;
        if (value.trim() === "") {
            this.errorTarget.textContent = "Required.";
            return false;
        } else {
            this.errorTarget.textContent = "";
            return true;
        }
    }

    submit(event) {
        if (!this.validate()) {
            event.preventDefault();
        }
    }
}
