import { Controller } from "stimulus"
import {
  validateDate, validateFields,
} from "../shared";

export default class extends Controller {
  static targets = ["modal", "form"]

  formChanged(event) {
    this.setChanged("true")
  }

  leavingPage(event) {
    if(this.hasChanges()) {
      event.preventDefault()

      event.returnValue = "You have unsaved changes"
      return event.returnValue
    }
  }

  allowSubmit(event) {
    if (this.validateDateInput()) {
      this.setChanged("false")
    } else{
      event.preventDefault()
    }
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  hasChanges() {
    return this.data.get("changed") == "true"
  }


  validateDateInput() {
    const dateIdPrefix = "event_date"

    const year = document.getElementById(`${dateIdPrefix}_1i`);
    const month = document.getElementById(`${dateIdPrefix}_2i`);
    const day = document.getElementById(`${dateIdPrefix}_3i`);
    const error = document.getElementById(`${dateIdPrefix}-error`) ||
                                document.getElementById(`${dateIdPrefix}-client-error`);

    const dateExists = day && month && year;

    return !dateExists ? true : validateFields(day, month, year, error);
  }

}
