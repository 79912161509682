import { Controller } from "stimulus"
import {
  validateDate,
} from "./shared";

export default class extends Controller {

  connect() {
    const eventIsArchived = this.data.get("archived");

    if (eventIsArchived == "true") {
      const formFields = this.element.querySelectorAll(["select", "input[type=text]", "input[type=file]", "input[type=number]", "input[type=submit]"]);

      for(var field of formFields) {
        field.disabled = eventIsArchived;
        field.readonly = eventIsArchived;
      }
    }
  }

  validateDateInput(idPrefix) {
    const year = document.getElementById( `${idPrefix}_1i`);
    const month = document.getElementById(`${idPrefix}_2i`);
    const day  = document.getElementById(`${idPrefix}_3i`);
    const error = document.getElementById(`${idPrefix}-error`) ||
                                document.getElementById(`${idPrefix}-client-error`);

    return validateDate(day, month, year, error);
  }

  validate() {
    const eventDateValid = this.validateDateInput("event_date");
    const openDateValid = this.validateDateInput("event_partner_invites_open_at");
    const closeDateValid = this.validateDateInput("event_partner_invites_close_at");

    return eventDateValid && openDateValid && closeDateValid;
  }

  submit(event) {
    if (!this.validate()) {
      event.preventDefault();
    }
  }

}
