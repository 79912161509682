import { Controller } from "stimulus"
import $ from "jquery"
import Clipboard from "clipboard"
import {
  setTooltip,
  hideTooltipInSeconds,
  validateDate, validateFields
} from "./shared";

export default class extends Controller {
  connect() {
    const buttons = this.element.querySelectorAll(".btn-copy-to-clipboard")

    for (var button of buttons) {
      $(`#${button.id}`).tooltip({
        trigger: "click", placement: "bottom"
      })
    }

    const clipboard = new Clipboard(".btn-copy-to-clipboard")

    clipboard.on("success", function(e) {
      setTooltip(e.trigger.id, "Copied!")
      hideTooltipInSeconds(e.trigger.id, 0.5)
    })

    clipboard.on("error", function(e) {
      setTooltip(e.trigger.id, "Failed!")
      hideTooltipInSeconds(e.trigger.id, 0.5)
    })
  }

  validateDateInput() {
    const dateIdPrefix = "calendar_date";

    const year = document.getElementById(`${dateIdPrefix}_1i`);
    const month = document.getElementById( `${dateIdPrefix}_2i`);
    const day  = document.getElementById(`${dateIdPrefix}_3i`);
    const error = document.getElementById(`${dateIdPrefix}-error`) ||
                                document.getElementById(`${dateIdPrefix}-client-error`);

    return validateFields(day, month, year, error);
  }

  submit(event) {
    if (!this.validateDateInput()) {
      event.preventDefault();
    }
  }

}
