import $ from "jquery"
import "bootstrap/js/dist/tooltip"
import * as constants from "./constants";

export function setTooltip(id, message) {
    $(`#${id}`).tooltip("hide")
        .attr("data-original-title", message)
        .tooltip("show")
}

export function hideTooltip(id) {
    $(`#${id}`).tooltip("hide")
}

export function hideTooltipInSeconds(id, seconds) {
    setTimeout(function() {
        hideTooltip(id)
    }, seconds)
}

export function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy)
            .catch(e => console.log("Copy to clipboard failed ", e));
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}

function isDayValid(day){
    return day.value === "" || (day.value >= constants.minDay && day.value <= constants.maxDay);
}

function isYearValid(year) {
    return year.value === "" || (year.value >= constants.minYear && year.value <= constants.maxYear)
}

function checkIfAllDateElementsExist(day, month, year) {
    return allElementsPresent(day, month, year) || noElementsPresent(day, month, year);
}

function allElementsPresent(day, month, year){
    return day?.value && month?.value && year?.value;
}

function noElementsPresent(day, month, year){
    return !day?.value && !month?.value && !year?.value;
}

function alterElementValidityClass(elements, areValid) {
    areValid ? elements?.forEach(el => el.classList.remove("is-invalid"))
            : elements?.forEach(el => el.classList.add("is-invalid"));
}

function alterErrorMessage(error, isValid){
    error.textContent = !isValid ? constants.dateInvalidErrorMessage : constants.emptyErrorMessage;
}

export function validateDate(day, month, year, error){
    const wholeDatePresent = checkIfAllDateElementsExist(day, month, year);
    alterElementValidityClass([day, month, year], wholeDatePresent);

    if(!wholeDatePresent) {
        alterErrorMessage(error, wholeDatePresent);
        return false;
    }

    return validateFields(day, month, year, error);
}

export function validateFields(day, month, year, error){
    alterElementValidityClass([month], true);

    const validDay = isDayValid(day);
    alterElementValidityClass([day], validDay);

    const validYear = isYearValid(year);
    alterElementValidityClass([year], validYear);

    const validDate = validDay && validYear;

    alterErrorMessage(error, validDate);

    return validDate;
}
